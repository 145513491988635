import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';

import Layout from '../../components/layout';
import ContentWrapper from '../../components/ContentWrapper';
import config from '../../config';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import getMessages from '../../locales/getMessages';

import Link from '../../components/Link';
import './index.css';

const messages = getMessages();
const { howToUseLink, howToUseAdvancedLink } = config;
const meta = {
  en: {
    title: 'How to use Evernote plugin - EverTool',
    subtitle: 'The documents of using EverTool with Evernote. Including writing Markdown, code highlight, image resize, colorize table, and Excel sheet with Evernote',
  },
  'zh-tw': {
    title: '如何使用 Evenote 的工具箱 - EverTool',
    subtitle: 'EverTool 的教學文件。教你如何在 Evernote 使用 Markdown、程式碼美化、圖片縮圖、表格塗色、以及整合 Excel 表格',
  },
  'zh-cn': {
    title: '如何使用印象笔记的工具箱 - EverTool',
    subtitle: 'EverTool 的教学文件。教你如何在印象笔记使用 Markdown、程式码美化、图片缩图、表格涂色、以及整合 Excel 表格',
  },
  ja: {
    title: 'Evernoteのプラグインを使用する方法 - EverTool',
    subtitle: 'EvernoteのでEverToolを使用しての文書。 Evernoteのと書き込み値下げ、コードのハイライト、画像リサイズ、色付けテーブル、およびExcelシートを含みます',
  },
};

const HowtoIndexRoute = ({ pageContext }) => {
  const { locale, url } = pageContext;
  return (
    <Layout
      pageContext={pageContext}
      seo={{
        title: meta[locale].title,
        subtitle: meta[locale].subtitle,
        hreflangs: pageContext.hreflangs,
        url,
        locale,
      }}
    >
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <ContentWrapper isMarkdown style={{ minHeight: '75vh' }}>
            <h1 className="how-to-use-title"><FormattedMessage id="howtouse" /></h1>
            {howToUseLink.map(item => (
              <div className="how-to-list-item" key={item.link}>
                {item.emoji}
                {' '}
                <Link to={`/how-to-use/${item.link}`}>{item.title[locale]}</Link>
              </div>
            ))}
            <hr />
            <h2 className="how-to-use-title"><FormattedMessage id="advanced" /></h2>
            {howToUseAdvancedLink.map(item => (
              <div className="how-to-list-item" key={item.link}>
                {item.emoji}
                {' '}
                <Link to={`/how-to-use/${item.link}`}>{item.title[locale]}</Link>
              </div>
            ))}
          </ContentWrapper>
        </div>
        <Footer />
      </IntlProvider>
    </Layout>
  );
};

export default HowtoIndexRoute;
